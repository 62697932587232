import React from 'react';
import clients from "../../assets/clients logos.png";
export default function Clients() {
  return (
    <div className='bg-port-green flex flex-wrap justify-center'>

            <img src={"https://res.cloudinary.com/dwwg1th5s/image/upload/f_auto,q_auto/v1/Clients/clients"} className='w-full' alt="IN Marketing clients" />


    </div>
  )
}
